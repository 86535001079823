import { baseUrl, localSettingsValues } from ".";

export const VENDOR_LOGO = baseUrl + "/public/adm_logo.png";
export const VENDOR_SECONDARY_LOGO = baseUrl + "/public/secondary_logo.png";

export const IMAGES = {
  WHITE_SPACE_BG: require("../assets/img/white-shape-bg.png"),
  HERO_BOTTOM: require("../assets/img/hero-bottom.svg"),
  ICON_VIEW_FILE: require("../assets/img/view_file_64.png"),
  ICON_SELECT_FILE: require("../assets/img/select_file_52.png"),
};

export const printHeader = "";

export const DEFAULT_PAGE_LIST_SIZE = "50";

export const FEE_RECEIPT_RECEIVED_FROM_OPTIONS = [
  "others",
  "student",
  "staff",
  "alumni",
];

export const LOGIN_FOR = [
  { text: "UG Courses", value: "ug", key: "enable_ug_admission" },
  { text: "PG Courses", value: "pg", key: "enable_pg_admission" },
  /*   { text: "MPhil Courses", value: "mphil", key: "enable_mphil_admission" }, */
];

export const aosInit = {
  offset: 100,
  duration: 600,
  easing: "ease-in-sine",
  delay: 100,
};

export const ROLES = {
  ALL: ["dev", "master_admin", "cashier", "accountant", "reception"],
  PROGRAMMER: ["dev", "master_admin"],
  ADMIN: ["dev", "master_admin"],
  SITE_ADMIN: ["dev", "master_admin", "site_admin"],
  ACCOUNTANT: ["dev", "master_admin", "accountant"],
  TEACHER: ["dev", "master_admin", "hod", "teacher"],
};

export const COMMUNITIES = [
  { text: "OC", value: "oc" },
  { text: "SC", value: "sc" },
  { text: "SC(A)", value: "sca" },
  /*   { text: "SLR", value: "slr" }, */
  { text: "ST", value: "st" },
  { text: "BC - Others", value: "bc" },
  { text: "BC - Muslim", value: "bcm" },
  { text: "MBC/DNC", value: "mbc" },
  { text: "Others", value: "others" },
];

export const DISTRICTS = [
  { text: "Tirunelveli", value: "Tirunelveli" },
  { text: "Thoothukudi", value: "Thoothukudi" },
  { text: "Kanniyakumari", value: "Kanniyakumari" },
  { text: "Tenkasi", value: "Tenkasi" },
];

export const TALUKS = [
  { text: "Radhapuram", value: "Radhapuram" },
  { text: "Thisayanvilai", value: "Thisayanvilai" },
  { text: "Nanguneri", value: "Nanguneri" },
  { text: "Agasteeswaram", value: "Agasteeswaram" },
  { text: "Vilavancode", value: "Vilavancode" },
  { text: "Thovalai", value: "Thovalai" },
  { text: "Srivaikuntam", value: "Srivaikuntam" },
  { text: "Thiruchendur", value: "Thiruchendur" },
  { text: "Thoothukudi", value: "Thoothukudi" },
  { text: "Sathankulam", value: "Sathankulam" },
  { text: "Others", value: "Others" },
];

export const BLOOD_GROUPS = [
  "A+",
  "A-",
  "A1+",
  "A1-",
  "A2+",
  "A2-",
  "A1B+",
  "A1B-",
  "A2B+",
  "A2B-",
  "AB+",
  "AB-",
  "B+",
  "B-",
  "O+",
  "O-",
];

export const RELIGIONS = [
  "hindu",
  "christian",
  "muslim",
  "sikh",
  "jain",
  "buddhist",
  "other",
];

export const OCCUPATIONS = [
  "business",
  "construction",
  "doctor",
  "driver",
  "engineer",
  "lawyer",
  "professor",
  "professional",
  "paramedical",
  "teacher",
  "unemployed",
  "other",
  "none",
];

export const COMMUNITY_CERTIFICATE_ISSUING_AUTHORITY = [
  " R.D.O",
  "ASST.COLLECTOR",
  "SUB.COLLECTOR",
  "PERSONAL ASSISTANT(GENERAL)",
  "TALUK TAHSILDAR",
  "TAHSILDAR",
  "HEAD QUARTERS DEPUTY TAHSILDAR",
  "SPECIAL DEPUTY TAHSILDAR",
  "ADDL.HQ.DEPUTY.TAHSILDAR",
  "ZONAL DEPUTY TAHSILDAR",
  "DEPUTY TAHSILDAR(CERTIFICATE)",
  "EXECUTIVE DEPUTY TAHSILDAR",
  "OTHER AUTHORITY EMPOWRED TO ISSUE CERTIFICATE",
];

export const LANGUAGES = [
  "Tamil",
  "Afrikaans",
  "Arabic",
  "Bengali",
  "Bulgarian",
  "Catalan",
  "Cantonese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "Lithuanian",
  "Malay",
  "Malayalam",
  "Panjabi",
  "English",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Javanese",
  "Korean",
  "Norwegian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovene",
  "Spanish",
  "Swedish",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
  "Welsh",
  "Sign language",
  "Algerian",
  "Aramaic",
  "Armenian",
  "Berber",
  "Burmese",
  "Bosnian",
  "Brazilian",
  "Bulgarian",
  "Cypriot",
  "Corsica",
  "Creole",
  "Scottish",
  "Egyptian",
  "Esperanto",
  "Estonian",
  "Finn",
  "Flemish",
  "Georgian",
  "Hawaiian",
  "Indonesian",
  "Inuit",
  "Irish",
  "Icelandic",
  "Latin",
  "Mandarin",
  "Nepalese",
  "Sanskrit",
  "Tagalog",
  "Tahitian",
  "Tibetan",
  "Gypsy",
  "Wu",
  "Other",
];

export const COURSE_TYPE_SORT_ORDER = localSettingsValues(
  "academic_course_types",
  "ug"
)?.split(",");
/*export const COURSE_TYPE_SORT_ORDER = [
  "ug3y",
  "ug5y",
  "pg",
  //"mphil",
  //"phd",
  //"diploma",
];*/

export const TABLE_STYLES = {
  tableCollapse: {
    borderCollapse: "collapse",
    border: "none",
  },
  borderBottom: {
    borderCollapse: "collapse",
    borderBottom: "1px solid black",
  },
  borderTop: {
    borderCollapse: "collapse",
    borderTop: "1px solid black",
  },
  borderExceptLeft: {
    borderCollapse: "collapse",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    padding: "3px",
  },
  borderExceptRight: {
    borderCollapse: "collapse",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    borderLeft: "1px solid black",
    padding: "3px",
  },
  borderAll: {
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "4px",
  },
  borderAllNoPadding: {
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "0px",
  },
  trHideborderAll: {
    display: "none",
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "4px",
  },
  borderAllHead: {
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "4px",
    backgroundColor: "#efefef",
    printColorAdjust: "exact",
  },
  borderTopBottom: {
    borderCollapse: "collapse",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    padding: "3px",
  },
  doubleBorderTop: { borderTop: "1px double black" },
};

export const DegreeType = [
  { text: "UG", value: "ug" },
  { text: "PG", value: "pg" },
  { text: "MPhil", value: "mphil" },
  { text: "PhD", value: "phd" },
  { text: "Diploma", value: "diploma" },
];
